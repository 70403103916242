var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-title',[_c('h3',[_vm._v(" عرض طلب حاجة ")])]),_c('hr',{staticClass:"invoice-spacing"}),_c('b-card-body',[_c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":" نوع الحاجة","label-for":"payment-method"}},[_c('v-select',{staticClass:"payment-selector",attrs:{"options":_vm.orderOption,"reduce":function (val) { return val.value; },"input-id":"payment-method","clearable":false,"disabled":""},model:{value:(_vm.Form.type),callback:function ($$v) {_vm.$set(_vm.Form, "type", $$v)},expression:"Form.type"}})],1)],1)],1)],1),(_vm.Form.type==0)?_c('b-card-body',{staticClass:"invoice-padding form-item-section"},[_c('h4',[_vm._v("تفاصيل الطلب")]),_c('div',{ref:"form",staticClass:"repeater-form"},_vm._l((_vm.Form.order_items),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('b-row',{staticClass:"flex-grow-1 px-1"},[_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('label',[_vm._v(" المادة ")])]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',[_vm._v(" الكمية ")])]),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('label',[_vm._v(" الواحدة ")])])],1),_c('div',{staticClass:"form-item-action-col"})],1),_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("المادة")]),_c('v-select',{staticClass:"mb-2 item-selector-title",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ItemData,"label":"name","clearable":false,"reduce":function (val) { return val.id; },"placeholder":"Select Item","disabled":""},model:{value:(item.item_id),callback:function ($$v) {_vm.$set(item, "item_id", $$v)},expression:"item.item_id"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("الكمية")]),_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","disabled":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"notes","state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("الواحدة")]),_c('v-select',{staticClass:"mb-2 item-selector-title",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ubitsOption,"label":"name","clearable":false,"reduce":function (val) { return val.id; },"placeholder":"Select Item","disabled":""},model:{value:(item.unit_id),callback:function ($$v) {_vm.$set(item, "unit_id", $$v)},expression:"item.unit_id"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"4"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"recive_date","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" تاريخ الاستلام ")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"حدد تاريخ ","disabled":""},model:{value:(item.recive_date),callback:function ($$v) {_vm.$set(item, "recive_date", $$v)},expression:"item.recive_date"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label-for":"notes"}},[_c('label',[_vm._v(" نوع مكان التسليم ")]),_c('v-select',{staticClass:"payment-selector",attrs:{"options":_vm.LocationType,"reduce":function (val) { return val.value; },"input-id":"payment-method","clearable":false,"disabled":""},on:{"input":function($event){item.recive_location=''}},model:{value:(item.location_type),callback:function ($$v) {_vm.$set(item, "location_type", $$v)},expression:"item.location_type"}})],1)],1),(item.location_type==1)?_c('b-col',{attrs:{"cols":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label-for":"notes"}},[_c('label',[_vm._v("مكان التسليم")]),_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"text","disabled":""},model:{value:(item.recive_location),callback:function ($$v) {_vm.$set(item, "recive_location", $$v)},expression:"item.recive_location"}})],1)],1):_vm._e(),(item.location_type==0)?_c('b-col',{attrs:{"cols":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label-for":"notes"}},[_c('label',[_vm._v(" المستودع")]),_c('v-select',{attrs:{"options":_vm.warehouseOption,"label":"name","clearable":false,"disabled":""},model:{value:(item.recive_location),callback:function ($$v) {_vm.$set(item, "recive_location", $$v)},expression:"item.recive_location"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"notes","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v("ملاحظة")]),_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"text","disabled":""},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex flex-column justify-content-between border-left py-50 px-25"})],1)])],1)}),1)]):_vm._e(),_c('b-row',[(_vm.Form.details)?_c('b-col',{attrs:{"md":"4"}},[_c('tags-input',{attrs:{"element-id":"value","existing-tags":_vm.tagList,"typeahead":true,"disabled":""},model:{value:(_vm.Form.details),callback:function ($$v) {_vm.$set(_vm.Form, "details", $$v)},expression:"Form.details"}}),_c('div')],1):_vm._e(),(_vm.Form.notes)?_c('b-col',{attrs:{"cols":"6","lg":"5"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("ملاحظة")]),_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"placeholder":"تفاصيل طلب غير موجود","disabled":""},model:{value:(_vm.Form.notes),callback:function ($$v) {_vm.$set(_vm.Form, "notes", $$v)},expression:"Form.notes"}})],1):_vm._e()],1)],1),_c('hr',{staticClass:"invoice-spacing"}),_c('b-col',{staticClass:"border-Primary mb-10",attrs:{"md":"12","xl":"4"}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }